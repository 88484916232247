import React from "react"

function SocialIcon(props) {
  let fillColor = props.fillColor
  switch (props.socialAccount) {
    case "Facebook":
      return (
        <svg
          className={props.socialAccount}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="23"
          viewBox="0 0 12 23"
        >
          <path
            fill={props.fillColor}
            fillRule="nonzero"
            d="M7.348 22.75v-9.84h3.136L11 9H7.348V6.293c0-.63.114-1.089.343-1.375.287-.344.817-.516 1.59-.516h2.063V.922C10.57.807 9.568.75 8.336.75c-1.518 0-2.736.451-3.652 1.354-.917.902-1.375 2.17-1.375 3.802V9H0v3.91h3.309v9.84h4.039z"
          />
        </svg>
      )

    case "Instagram":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="23"
          viewBox="0 0 22 23"
        >
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g
              fill={props.fillColor}
              fillRule="nonzero"
              transform="translate(-1072 -274)"
            >
              <path d="M1087.454 296.484c1.753-.083 3.306-.483 4.585-1.767 1.28-1.28 1.68-2.832 1.768-4.585.102-1.807.102-7.217 0-9.024-.083-1.753-.484-3.305-1.768-4.585-1.28-1.279-2.832-1.68-4.585-1.767-1.807-.103-7.222-.103-9.028 0-1.748.083-3.301.483-4.585 1.763-1.284 1.279-1.68 2.832-1.768 4.585-.102 1.806-.102 7.221 0 9.028.083 1.753.484 3.306 1.768 4.585 1.284 1.28 2.832 1.68 4.585 1.767 1.806.103 7.221.103 9.028 0zm-4.512-1.894c-1.591 0-5.014.127-6.45-.44a3.693 3.693 0 01-2.08-2.08c-.571-1.44-.44-4.858-.44-6.45 0-1.592-.126-5.014.44-6.45a3.693 3.693 0 012.08-2.08c1.44-.571 4.859-.44 6.45-.44 1.592 0 5.015-.127 6.45.44a3.693 3.693 0 012.08 2.08c.543 1.368.451 4.521.44 6.198v.252c0 1.592.132 5.015-.44 6.45a3.693 3.693 0 01-2.08 2.08c-1.44.572-4.858.44-6.45.44zm5.84-13.501a1.309 1.309 0 100-2.617 1.309 1.309 0 000 2.617zm-5.84 10.141a5.601 5.601 0 005.61-5.61 5.601 5.601 0 00-5.61-5.61 5.601 5.601 0 00-5.61 5.61 5.601 5.601 0 005.61 5.61zm0-1.962a3.654 3.654 0 01-3.647-3.648 3.65 3.65 0 013.647-3.647 3.65 3.65 0 013.648 3.647 3.654 3.654 0 01-3.648 3.648z"></path>
            </g>
          </g>
        </svg>
      )
    case "Twitter":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="21"
          viewBox="0 0 25 21"
        >
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g
              fill={props.fillColor}
              fillRule="nonzero"
              transform="translate(-1133 -275)"
            >
              <path d="M1140.868 295.777c9.423 0 14.578-7.804 14.578-14.578 0-.222 0-.444-.016-.666 1-.73 1.872-1.634 2.57-2.649-.92.397-1.92.682-2.95.793a5.11 5.11 0 002.252-2.823 10.087 10.087 0 01-3.252 1.237 5.112 5.112 0 00-3.744-1.618c-2.84 0-5.123 2.3-5.123 5.123 0 .397.047.794.127 1.174a14.564 14.564 0 01-10.565-5.361 5.085 5.085 0 00-.698 2.585 5.12 5.12 0 002.284 4.267 5.16 5.16 0 01-2.316-.65v.064c0 2.49 1.761 4.552 4.109 5.028a5.418 5.418 0 01-1.349.175c-.333 0-.65-.032-.967-.08a5.133 5.133 0 004.79 3.554 10.262 10.262 0 01-6.36 2.189c-.429 0-.826-.016-1.238-.064a14.48 14.48 0 007.868 2.3z"></path>
            </g>
          </g>
        </svg>
      )

    case "Google":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
        >
          <path
            fill={props.fillColor}
            fillRule="nonzero"
            d="M11.156 21.406c2.034 0 3.832-.444 5.393-1.332a9.29 9.29 0 0 0 3.63-3.695c.86-1.576 1.29-3.366 1.29-5.371 0-.573-.058-1.175-.172-1.805h-10.14v3.695h6.058a5.733 5.733 0 0 1-.73 1.891 5.95 5.95 0 0 1-1.934 1.848c-.974.573-2.106.86-3.395.86a6.2 6.2 0 0 1-3.308-.925 7.035 7.035 0 0 1-2.407-2.449c-.601-1.017-.902-2.141-.902-3.373 0-1.432.387-2.707 1.16-3.824a6.622 6.622 0 0 1 2.836-2.32c1.175-.516 2.363-.688 3.567-.516 1.289.172 2.391.702 3.308 1.59l2.879-2.793C16.312 1.025 13.935.094 11.156.094c-1.919 0-3.695.48-5.328 1.44A10.768 10.768 0 0 0 1.94 5.421 10.33 10.33 0 0 0 .5 10.75c0 1.92.48 3.695 1.44 5.328a10.768 10.768 0 0 0 3.888 3.889 10.33 10.33 0 0 0 5.328 1.44z"
          />
        </svg>
      )

    case "Youtube":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="17"
          viewBox="0 0 25 17"
        >
          <path
            fill={props.fillColor}
            fillRule="nonzero"
            d="M12.375 17l3.91-.043c2.75-.086 4.498-.23 5.242-.43.516-.143.96-.4 1.332-.773.373-.372.63-.802.774-1.29.2-.773.344-1.961.43-3.566l.042-2.148-.043-2.148c-.085-1.576-.229-2.75-.43-3.524a2.979 2.979 0 0 0-.773-1.332 2.979 2.979 0 0 0-1.332-.773c-.744-.2-2.492-.344-5.242-.43L12.375.5l-3.91.043c-2.75.086-4.498.23-5.242.43-.516.143-.96.4-1.332.773-.373.373-.63.817-.774 1.332-.2.774-.344 1.948-.43 3.524A60.855 60.855 0 0 0 .645 8.75l.042 2.148c.086 1.605.23 2.793.43 3.567.143.487.401.917.774 1.289.372.372.816.63 1.332.773.744.2 2.492.344 5.242.43l3.91.043zm-2.406-4.727V5.27l6.144 3.48-6.144 3.523z"
          />
        </svg>
      )
  }
}

export default SocialIcon
