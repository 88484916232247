import React from 'react'

const BurgerIcon = () => {
    return (
        <div className="burger">
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
        </div>
    )
}

export default BurgerIcon
